body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (prefers-color-scheme: light) {
  .ace_gutter {
    background-color: #FFFFFF !important;
  }
  .ace_gutter-active-line {
    background-color: #FFFFFF !important;
  }
}
@media (prefers-color-scheme: dark) {
  .ace_gutter {
    background-color: #1e293b !important;
  }
  .ace_gutter-active-line {
    background-color: #1e293b !important;
  }
  .ace_editor {
    background-color: #1e293b !important;
  }
}

.react-contexify__theme--dark {
  background-color: rgba(56, 62, 84, 0.98) !important;
}
/* .ace_editor (prefers-color-scheme: {
  background: #FFFFFF !important;
} */


@layer components {
  .bg-primary {
    @apply bg-white dark:bg-slate-800;
  }
  .bg-secondary {
    @apply bg-slate-100 dark:bg-slate-900;
  }
  .select {
    @apply dark:text-zinc-300 bg-slate-100 dark:bg-slate-800;
  }

  .secondary-button {
    @apply bg-slate-100 shadow-sm whitespace-pre rounded-md p-2 font-bold text-slate-400 cursor-pointer dark:bg-slate-700 dark:shadow-xl;
  }

  
}

@tailwind base;
@tailwind components;
@tailwind utilities;